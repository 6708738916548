import React from 'react'
import { Card, Text, IconButton, Box } from 'theme-ui'
import { FaPhone, FaEnvelope } from 'react-icons/fa'
import Section from '@components/Section'
//import { useSiteConfig } from '@helpers'
import Img from 'gatsby-image'
import Divider from '@components/Divider'

const styles = {
  sidecard: {
    marginTop: -25
  },
  center: {
    textAlign: 'center'
  },
  map: {
    display: ['none', 'none', 'none', 'block']
  },
  heading: {
    a: {
      color: 'black'
      //textDecoration: 'none'
    }
  }
}

const ContactInfo = props => {
  //const { phone, address, email } = useSiteConfig()
  const addressL1 = '8911 Lakewood Dr Ste 22B'
  const addressL2 = 'Windsor, CA 95492'
  const addressL3 = 'United States of America'

  const fluid = props.data.staticMap.childFile.childImageSharp.fluid
  //const mapUrl = props.data.staticMap.mapUrl
  const mapUrl =
    'https://maps.google.com/?q=8911%20Lakewood%20Dr,%20Suite%2022B,%20Windsor,%20CA%2095492,%20USA'

  return (
    <Section title='Our Location'>
      <Card variant='paper' sx={styles.sidecard}>
        <Box sx={styles.map}>
          <a href={mapUrl} target='_blank' rel='noopener noreferrer' aria-label="Goolge Map of Pacific Beauty Location">
            <Img
              fluid={fluid}
              title=''
              alt=''
              loading='lazy'
              imgStyle={{
                width: '100%',
                maxWidth: '384',
                maxHeight: '384',
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center'
              }}
              style={{
                width: '100%',
                maxWidth: '384',
                maxHeight: '384',
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                paddingLeft: 5,
                paddingRight: 5
              }}
              placeholderStyle={{
                width: '100%',
                maxWidth: '384',
                maxHeight: '384',
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center'
              }}
            />
          </a>
        </Box>
        <Divider />
        <Text variant='h3' sx={styles.heading}>
          <a
            href='https://pac-trade.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pack Trading Group, Inc
          </a>
          <br />
          dba Pacific Beauty
        </Text>
        <Text variant='p'>
          {addressL1}
          <br />
          {addressL2}
          <br />
          {addressL3}
        </Text>
        <Text variant='p'>
          <IconButton variant='simple' role='presentation'>
            <FaEnvelope />
          </IconButton>
          <a href='mailto:info@pac-beauty.com'>info@pac-beauty.com</a>
          <br />
          <IconButton variant='simple' role='presentation'>
            <FaPhone />
          </IconButton>
          <a href='tel:+1 707-234-8221'>+1 (707) 234-8221</a>
        </Text>
      </Card>
    </Section>
  )
}

export default ContactInfo

import React, { useState } from 'react'
import axios from 'axios'
import {
  Box,
  Label,
  Input,
  Textarea,
  Button,
  Spinner,
  Container,
  Card,
  Text
} from 'theme-ui'
import { Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactInfo from '../widgets/ContactInfo'
import { graphql } from 'gatsby'
import Breadcrumbs from '../components/Breadcrumbs/PageCrumbs'

const styles = {
  flex: {
    display: 'flex'
  },
  sidebar: {
    display: ['none', 'none', 'none', 'block'],
    minWidth: `auto`
  }
}

const MyForm = ({ data }) => {
  const title = 'Contact Us'
  const successMsg = "Thank you for contacting us. We'll get back to you soon!"
  const errorMsg1 = 'There was an error submitting your message:  '
  const errorMsg2 =
    '  Please try again or contact us directly at info@pac-beauty.com'
  const endpoint = 'https://getform.io/f/648f5bb8-def2-46a1-b4f9-b118e31e22f5'

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: 'post',
      url: endpoint,
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, successMsg, form)
      })
      .catch(r => {
        handleServerResponse(
          false,
          errorMsg1 + r.response.data.error + errorMsg2,
          form
        )
      })
  }
  return (
    <>
      <Seo
        title='Contact Pacific Beauty and Start Your Own Line of Branded Skincare'
        description='Please fill out the form below to get in touch with us about any questions, comments, or business inquiries. A product catalog and individual product brochures are available upon request. We look forward to hearing from you.'
      />
	  <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs title={title} />
      </Container>
      <Divider space={2} />
      <Container variant='wide' sx={styles.flex}>
        <Main>
          <PageTitle
            header="Let's Connect"
            subheader='Please fill out the form below to get in touch with us about any questions, comments, or business inquiries.   A product catalog and individual product brochures are available upon request.  We look forward to hearing from you.'
          />
          <Divider />
          <Card variant='paper' sx={styles.sidecard}>
            <form onSubmit={handleOnSubmit}>
              <Box variant='forms.row'>
                <Box variant='forms.column'>
                  <Label htmlFor='contact-form-name'>Name (required)</Label>
                  <Input
                    type='text'
                    id='contact-form-name'
                    name='name'
                    required
                  />
                </Box>
                <Box variant='forms.column'>
                  <Label htmlFor='contact-form-company'>Company Name</Label>
                  <Input type='text' id='contact-form-company' name='company' />
                </Box>
              </Box>
              <Box variant='forms.row'>
                <Box variant='forms.column'>
                  <Label htmlFor='contact-form-email'>Email (required)</Label>
                  <Input
                    type='email'
                    placeholder='email@example.com'
                    id='contact-form-email'
                    name='email'
                    required
                  />
                </Box>
                <Box variant='forms.column'>
                  <Label htmlFor='contact-form-phone'>Phone Number</Label>
                  <Input
                    type='tel'
                    placeholder='(xxx) xxx-xxxx'
                    id='contact-form-phone'
                    name='phone'
                  />
                </Box>
              </Box>
              <Box variant='forms.row'>
                <Box variant='forms.column'>
                  <Label htmlFor='contact-form-country'>Country</Label>
                  <Input type='text' id='contact-form-country' name='country' />
                </Box>
                <Box variant='forms.column'>
                  <Label htmlFor='contact-form-products'>
                    Products You're Interested In
                  </Label>
                  <Textarea name='products' id='contact-form-products' />
                </Box>
              </Box>
              <Box variant='forms.row'>
                <Label htmlFor='contact-form-message'>
                  Your Message (required)
                </Label>
                <Textarea name='message' id='contact-form-message' required />
              </Box>
              <Divider space={3} />
              <Button
                variant={serverState.submitting ? 'disabled' : 'primary'}
                disabled={serverState.submitting}
                type='submit'
                required
              >
                Submit {serverState.submitting && <Spinner size='20' />}
              </Button>
              {serverState.status && (
                <Text
                  variant='p'
                  className={!serverState.status.ok ? 'errorMsg' : ''}
                >
                  {serverState.status.msg}
                </Text>
              )}
            </form>
          </Card>
        </Main>
        <Sidebar sx={styles.sidebar}>
          <ContactInfo data={data} />
        </Sidebar>
      </Container>
    </>
  )
}

export const query = graphql`
  {
    staticMap {
      childFile {
        childImageSharp {
          fluid(cropFocus: CENTER, maxWidth: 384, maxHeight: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mapUrl
    }
  }
`

export default MyForm
